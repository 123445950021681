import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes.js"
// push 传入当前值(空值)进行错误处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        // noScrollQuery 改变query不触发滚动
        if (to.path === from.path && to.meta.noScrollQuery) {
            return null
        }
        return { x: 0, y: 0 }
    }
})


router.beforeEach((to, from, next) => {
    // 判断登录
    let loginPageArr = [
        '/game-collect', '/team-center', '/wheel', '/wallet', '/layout/task-center', '/lotter',
        "/singin", "/pdd", '/layout/edemption-code', '/sport-betting', '/five-bessings',
        "/luckySport"
    ]
    const isLogin = localStorage.getItem('ft_tokenInfo')
    if (process.env.VUE_APP_SINGLE_LOGIN === "true" && !isLogin && to.path !== "/login") {
        next("/login")
        return
    }
    if (loginPageArr.includes(to.path)) {
        if (!isLogin) {
            if (from) {
                next(`?modal=LoginRegistration&data=tab-1`)
            } else {
                next(`/?modal=LoginRegistration&data=tab-1`)
            }
            return;
        } else {
            // 弹窗路由劫持
            if (initPopRouter(to, from, next)) {
                next()
            }
        }
    } else {
        // 弹窗路由劫持
        if (initPopRouter(to, from, next)) {
            next()
        }
    }
})

// 初始化弹窗路由
const initPopRouter = (to, from, next) => {
    let popArr = ["/wheel"]
    if (popArr.includes(to.path)) {
        next({
            path: from.path || "/", query: {
                dialog: to.path.split('/')[1]
            }
        })
    } else {
        return true
    }
}


export default router
